/*******************************************************************************
 * Themes
 ******************************************************************************/

:root {
  --global-bg-color: #{$white-color};
  --global-code-bg-color: #{$code-bg-color-light};
  --global-text-color: #{$black-color};
  --global-text-color-light: #{$grey-color};
  --global-theme-color: rgba(50, 88, 135,1); //#8a1538;
  --global-hover-color: rgba(50, 88, 135,1); //#8a1538;
  --global-footer-bg-color: #{$grey-color-dark};
  --global-footer-text-color: #{$grey-color-light};
  --global-footer-link-color: #{$white-color};
  --global-distill-app-color: #{$grey-color};
  --global-divider-color: rgba(0, 0, 0, .1);
  --global-card-bg-color: #{$white-color};

  .fa-sun {
    display: none;
  }

  .fa-moon {
    padding-left: 10px;
    padding-top: 12px;
    display: block;
  }

  .repo-img-light {
    display: block;
  }

  .repo-img-dark {
    display: none;
  }
}

html[data-theme='dark'] {
  --global-bg-color: #{$grey-color-dark};
  --global-code-bg-color: #{$code-bg-color-dark};
  --global-text-color: #{$grey-color-light};
  --global-text-color-light: #{$grey-color-light};
  --global-theme-color: #{$cyan-color};
  --global-hover-color: #{$cyan-color};
  --global-footer-bg-color: #{$grey-color-light};
  --global-footer-text-color: #{$grey-color-dark};
  --global-footer-link-color: #{$black-color};
  --global-distill-app-color: #{$grey-color-light};
  --global-divider-color: #424246;
  --global-card-bg-color: #{$grey-900};

  .fa-sun {
    padding-left: 10px;
    padding-top: 12px;
    display: block;
  }

  .fa-moon {
    display: none;
  }

  .repo-img-light {
    display: none;
  }

  .repo-img-dark {
    display: block;
  }
}